import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';

import { database } from '../../firebase/config';

export const ScoresList = ({ databaseName, userId }) => {
  const [scores, setScores] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getScores = () => {
    const scoresCollection = collection(database, databaseName);
    getDocs(scoresCollection)
      .then(response => {
        const scrs = response.docs.map(doc => ({
          data: doc.data(),
          id: doc.id,
        }));
        setScores(scrs.sort((a, b) => b.data.score - a.data.score));
        setIsLoading(false);
      })
      .catch(error => {
        setError(setError(error.message))
        setIsLoading(false);
      });
  };

  useEffect(() => getScores(), []);

  return (
    isLoading
      ? <p className="loading">Loading...</p>
      : error
        ? <p className="error">{error}</p>
        : <ul className="scores-list">
          {scores ? scores.map((score, idx) => (
            <li key={score.id} className={`scores-list__item ${userId === score.data.id ? 'scores-list__item--me' : ''}`}>
              <p className="scores-list__place">{idx + 1}.</p>
              <p className="scores-list__username">{score.data.username}</p>
              <p className="scores-list__score">{score.data.score}</p>
            </li>
          )) : <li className="error">No scores yet</li>}
        </ul>
  );
};
