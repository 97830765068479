// All sprites loaded to the game are defined here
// SPRITES and AVATARS are loaded from '/public/sprites' directory
// SPRITES and AVATARS are fully customizable by client

// Firebase: databaseName is the name of the collection in Firebase

export const configBiznes = {
  mode: 'biznes',
  backgrounds: {
    gameBg: '../../assets/bg-biz.png',
  },
  databaseName: 'scoresBusiness',
  spritesRoot: '/sprites/',
  sprites: {
    answer1: 'answer1.png',
    answer2: 'answer2.png',
    answer3: 'answer3.png',
    answer4: 'answer4.png',
    answer5: 'answer5.png',
    comment: 'comment.svg',
    commentLeft: 'commentLeft.svg',
    commentRight: 'commentRight.svg',
    player: 'duck.svg',
    playerMovingLeft: 'duck-moving-left.svg',
    playerMovingRight: 'duck-moving-right.svg',
  },
  avatars: [
    {
      id: 'avatar1',
      icon: 'artur.png',
      name: 'Artur THE BOSS',
      texts: [
        'Ile macie zaproszonych gości?',
        'Mam ponad 7 tysięcy kontaktów, a Ty?',
        'Wiesz, co oznacza rekomendacja?',
        'Ile kosztuje 1 godzina Twojego czasu?',
        'Jesteś w stanie przyjąć nowych klientów?',
        'Jaki jest Twój golden nugget?',
      ],
    },
    {
      id: 'avatar2',
      icon: 'przemek.png',
      name: 'Przemek PREZES',
      texts: [
        'Nauczę Cię wymawiać słowo „hygge”',
        'Nie sprzedawaj, jeśli nie wiesz komu sprzedajesz',
        'Poproszę wszystkich o wyciszenie mikrofonów',
        'Poproszę wszystkich o zmianę podpisów',
        'Przeczytałem jedna książkę i zarobiłem 100 tysięcy, a Ty?',
      ],
    },
    {
      id: 'avatar3',
      icon: 'piotr.png',
      name: 'Piotr TOP PERFORMER',
      texts: [
        'Zaprosiłem 25 gości na spotkanie, a Ty?',
        'Jak masz 1000 kontaktów, to możesz działać na LinkedIn',
        'Piszę posty codziennie, a Ty?',
        'Zostawiam komentarze wszystkim członkom grupy na bieżąco',
        'Byłem na 1:1 ze wszystkimi w grupie, po kilka razy, a Ty?',
        'Wysyłam Ci swój arkusz SID tydzień przed spotkaniem',
      ],
    },
    {
      id: 'avatar4',
      icon: 'gosc.png',
      name: 'Gość',
      texts: [
        'Jestem pierwszy raz, wiec się trochę rozgadam',
        '45 sekund mi zdecydowanie nie wystarczy',
        'Postanowiłem nie wyciszać mikrofonu, bo tak mi wygodnie',
        'Wrzucę wizytówkę na czat kilka razy, lepiej zapamietają',
        'Wyłącze kamerkę i będę miał was na baczności',
        'Uwielbiam ten klimat, dołączam do grupy!',
      ],
    },
  ],
};
