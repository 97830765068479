// All sprites loaded to the game are defined here
// SPRITES and AVATARS are loaded from '/public/sprites' directory
// SPRITES and AVATARS are fully customizable by client

// Firebase: databaseName is the name of the collection in Firebase

export const configAffirmations = {
  mode: 'affirmation-catcher',
  backgrounds: {
    gameBg: '../../assets/bg.png',
  },
  databaseName: 'scoresAffirmationsCatcher',
  spritesRoot: '/sprites/',
  sprites: {
    answer1: 'answer1.png',
    answer2: 'answer2.png',
    answer3: 'answer3.png',
    answer4: 'answer4.png',
    answer5: 'answer5.png',
    comment: 'comment.svg',
    commentLeft: 'commentLeft.svg',
    commentRight: 'commentRight.svg',
    player: 'duck.svg',
    playerMovingLeft: 'duck-moving-left.svg',
    playerMovingRight: 'duck-moving-right.svg',
  },
  avatars: [
    {
      id: 'avatar1',
      icon: 'ewa.png',
      name: 'Ewa',
      texts: [
        'Mam dużo energii',
        'Moje ciało jest zdrowe',
        'Czuję witalność i spokój ducha',
        'Mam w sobie pozytywną energię',
        'Odżywiam moje ciało zdrowym jedzeniem',
        'Akceptuję moje ciało takim, jakie jest',
        'Moje myśli są klarowne',
        'Dbam o swoją wewnętrzną harmonię',
        'Każdy wysiłek przynosi mi korzyści',
        'Dbam o swoje ciało z miłością',
        'Wybieram zdrowe nawyki',
        'Akceptuję i rozumiem swoje emocje',
        'Dbam o równowagę między pracą a odpoczynkiem',
        'Dbam o ciało, umysł i duszę równocześnie',
        'Moje nawyki służą mojemu dobrostanowi',
        'Regularne ćwiczenia przynoszą mi radość',
        'Moje ciało jest w dobrej formie',
        'Odczuwam spokój i równowagę w każdej chwili',
      ],
    },
    {
      id: 'avatar2',
      icon: 'piotrek.png',
      name: 'Piotr',
      texts: [
        'Akceptuję siebie bezwarunkowo i z szacunkiem',
        'Żyję zgodnie z moimi wartościami i celami życiowymi',
        'Pozwalam sobie na pełne doświadczanie życia',
        'Przyciągam do siebie zdrowe relacje i sytuacje',
        'Wybieram swój nastrój',
        'Wierzę w swoje umiejętności i potencjał',
        'Moje życie jest pełne radości',
        'Każdy dzień przynosi mi nowe możliwości',
        'Moja pewność siebie rośnie',
        'Jestem udaną i utalentowaną osobą',
        'Wierzę w swoje cele',
        'Otwieram się na nowe pomysły',
        'Zaczynam swój dzień od pozytywnych intencji',
        'Przepełnia mnie wdzięczność',
        'Moje życie jest przygodą',
        'Działam z zaangażowaniem i wiarą w sukces',
        'Przyciągam dobre doświadczenia',
        'Ufam, że jestem na właściwej ścieżce',
      ],
    },
    {
      id: 'avatar3',
      icon: 'anna.png',
      name: 'Anna',
      texts: [
        'Otaczam się inspirującymi ludzmi',
        'Przyjmuję dobro od innych',
        'Cieszę się z sukcesów i radości bliskich mi osób',
        'Znajduję równowagę między dawaniem i przyjmowaniem',
        'Wspieram innych w realizacji ich marzeń',
        'Ludzie mnie wspierają i życzą sukcesu',
        'Mam dobre relacje rodzinne',
        'Jestem wsparciem, zrozumieniem i uczciwością',
        'Inspiruję innych',
        'Zasługuję na szczęśliwe i zdrowe związki',
        'Przyciągam pozytywne relacje do mojego życia',
        'Jestem dobrym przyjacielem i partnerem',
        'Otwieram się na zrozumienie innych',
        'Komunikuję się otwarcie i autentycznie',
        'Jestem magnesem na pozytywne i zdrowe relacje',
        'Moje relacje są pełne zrozumienia i współczucia',
        'Otaczam się kochającymi i wspierającymi ludźmi',
        'Przyciągam uznanie i pozytywne opinie',
      ],
    },
    {
      id: 'avatar4',
      icon: 'marek.png',
      name: 'Marek',
      texts: [
        'Kocham to, co robię',
        'Osiągam swoje cele z łatwością i przyjemnością',
        'Każdy dzień to nowa szansa na osiągnięcie',
        'Moja praca daje mi spełnienie',
        'Lubię swoją pracę',
        'Moja praca zmienia życia klientów',
        'Działam konsekwentnie',
        'Lubię wyzwania',
        'Otwieram się na nowe okazje',
        'Pozwalam sobie osiągnąć znaczący sukces',
        'Otwieram się na sukces w każdej sferze',
        'Kreuję życie pełne możliwości i sukcesów',
        'Mam moc kreowania kariery, o jakiej marzę',
        'Sukces jest moim naturalnym stanem bycia',
        'Jestem otwarty na nowe perspektywy zawodowe',
        'Rozwijam się i osiągam cele',
        'Wartość mojej pracy jest doceniana',
        'Moje sukcesy inspirują innych',
      ],
    },
  ],
};
