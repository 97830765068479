import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAJgT9d3ZARCLeuHzLgF3tWiuoOxbt31-Q",
  authDomain: "quack-catcher.firebaseapp.com",
  projectId: "quack-catcher",
  storageBucket: "quack-catcher.appspot.com",
  messagingSenderId: "825656914812",
  appId: "1:825656914812:web:9afd2473c9494053702af9"
};

// initialize firebase
const app = initializeApp(firebaseConfig);

// initialize firestore
export const database = getFirestore(app);
