import { useEffect, useRef, useState} from 'react';
import kaboom from 'kaboom';
import { isMobile } from 'react-device-detect';
import { addDoc, collection } from 'firebase/firestore';

import bg from '../../assets/bg.png';
import bg21s from '../../assets/bg-21s.png';
import bgBiz from '../../assets/bg-biz.png';
import bgNder from '../../assets/bg-nder.jpg';
import bgXtm from '../../assets/bg-xtm.jpg';
import { GameManager } from '../../classes/GameManager';
import { database } from '../../firebase/config';
import { switchModes } from '../../helpers';

export const Game = ({
  gameConfig,
  username,
  setGameResult,
  setIsGameFinished,
}) => {
  const canvasRef = useRef(null);
  const [error, setError] = useState(null);

  const addScore = (id, score, username, callbackSuccess = null) => {
    const scoresCollection = collection(database, gameConfig.databaseName);
    addDoc(scoresCollection, {
      id,
      username,
      score,
    })
      .then(() => {
        if (callbackSuccess) callbackSuccess();
      })
      .catch(error => setError(error.message));
  };

  useEffect(() => {
    const k = kaboom({
      width: isMobile ? window.innerWidth : 1000,
      height: isMobile ? window.innerHeight : 550,
      scale: 1,
      canvas: canvasRef.current,
      background: [0, 0, 0, 0],
      fullScreen: isMobile,
    });

    const game = new GameManager(k, gameConfig);

    // Start the game
    game.startGame(
      addScore,
      setIsGameFinished,
      setGameResult,
      username,
    );
  }, [username]);

  return (
    <div className="game-container">
      <div className="game-container__background-holder" style={{ backgroundImage: `url(${switchModes(gameConfig.mode, bgBiz, bgXtm, bgNder, bg21s, bg)})` }} />
      {!isMobile && <h2>{`Let the game begin ${username || ''}!`}</h2>}
      <div className={`game-container__game-board ${isMobile ? 'game-container__game-board--mobile' : ''}`}>
        <div
          className="game-container__game"
          style={{ backgroundImage: `url(${switchModes(gameConfig.mode, bgBiz, bgXtm, bgNder, bg21s, bg)})` }}
        >
        </div>
        <canvas ref={canvasRef} className="game-container__canvas" />
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};
