import { useEffect, useState } from 'react';
import { AndroidView, IOSView, isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';

import '../../app.sass';
import logo from '../../assets/logo.svg';
import logo21s from '../../assets/logo-21s.svg';
import logoBiz from '../../assets/logo-biz.svg';
import logoNder from '../../assets/logo-nder.svg';
import wellPlayLogo from '../../assets/logo-wellplay-symbol-white.svg';
import logoWhite from '../../assets/logo-white.svg';
import logoXtm from '../../assets/logo-xtm.svg';
import {
  configAffirmations,
  config21s,
  configXtm,
  configBiznes,
  configNder,
} from '../../gameConfig';
import { MODES, switchModes } from '../../helpers';
import { Game, OrientationInfo, ScoresList } from '..';

export const GameContainer = () => {
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [username, setUsername] = useState('');
  const [isGameFinished, setIsGameFinished] = useState(false);
  const [gameResult, setGameResult] = useState({ userId: null, score: 0 });
  const [isLandscape, setIsLandscape] = useState(false);
  const [mode, setMode] = useState(null);
  const [gameConfig, setGameConfig] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPwa, setIsPwa] = useState(false);
  const [logoMode, setLogoMode] = useState(logo);

  const { mode: modeParam } = useParams();

  useEffect(() => {
    setMode(modeParam);
  }, []);

  useEffect(() => {
    setGameConfig(switchModes(
      mode,
      configBiznes,
      configXtm,
      configNder,
      config21s,
      configAffirmations,
    ));
    setLogoMode(switchModes(
      mode,
      logoBiz,
      logoXtm,
      logoNder,
      logo21s,
      logo,
    ));
  }, [mode]);

  useEffect(() => {
    if (!isMobile) return;

    const screenOrientation = window.matchMedia('(orientation: landscape)');

    const setOrientation = (e) => setIsLandscape(e.matches);
    setIsLandscape(screenOrientation.matches);

    screenOrientation.addEventListener('change', setOrientation);

    // Detect if app runs from PWA
    setIsPwa(window.matchMedia('(display-mode: fullscreen)').matches);

    return () => {
      screenOrientation.removeEventListener('change', setOrientation);
    };

  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  const addToHomeScreen = () => {
    alert('To add this app to your home screen, tap the Share button and then select "Add to Home Screen."');
  };

  return (
    <div className="app">
      {(isGameStarted && !isGameFinished) ? (
        <>
          {!isMobile && <header className="app-header--small">
            <div className="flex-container">
              <img
                className="app-logo app-logo--small" alt="logo"
                src={mode === MODES.affirmations ? logoWhite : logoMode}
              />
              <h1>Affirmation catcher{mode === MODES.biznes ? <span className="business-header">Business mode</span> : mode === MODES.xtm ? <span className="business-header">XTM mode</span> : null}</h1>
            </div>
            <button className="app-link app-link--small" onClick={() => setIsGameFinished(true)}>
              Close
            </button>
          </header>}
          <main className={`main${isMobile ? ' main--mobile' : ''}`}>
            {(isLandscape || !isMobile) ? (
              <Game
                gameConfig={gameConfig}
                setIsGameFinished={setIsGameFinished}
                setGameResult={setGameResult}
                username={username}
              />
            ) : <div className="orientation-warning"><OrientationInfo /></div>}
          </main>
        </>
      ) : (
        <header className={`app-header${isGameFinished ? ' app-header--finish' : ''}`}>
          {isGameFinished && <a className="app-header__finish-link" href="http://wellplay.pl/" target="_blank" rel="noreferrer noopener">Wejdź na <img src={wellPlayLogo} alt='' />wellplay.pl, aby stworzyć swoją własną grę!</a>}
          <img src={logoMode} className={`app-logo${mode === MODES.affirmations ? ' app-logo--default' : ''}`} alt="logo" />
          {isGameFinished ? (
            <>
              <p className="your-score">Your score: <span>{gameResult.score}</span></p>
              <ScoresList databaseName={gameConfig.databaseName} userId={gameResult.userId} />
              <button type="button" className="app-link play-button" onClick={() => {
                setIsGameFinished(false);
                setGameResult({ userId: null, score: 0 })
                setIsGameStarted(false);
              }}>OK</button>
            </>
          ) : (
            <form onSubmit={() => setIsGameStarted(true)} className="form-container">
              <div className="username-container">
                <label className="username-label" htmlFor="name">Username</label>
                <input
                  autoFocus
                  className="username-input"
                  id="name"
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              {isMobile && !isLandscape && <OrientationInfo />}
              {!isPwa && isMobile && (
                <>
                  <AndroidView>
                    <button className="app-link full-screen-btn" type="button" onClick={toggleFullscreen}>{isFullscreen ? 'Exit full screen' : 'Full screen'}</button>
                  </AndroidView>
                  <IOSView>
                    <button className="app-link full-screen-btn" type="button" onClick={addToHomeScreen}>Add to Home Screen</button>
                  </IOSView>
                </>
              )}
              <button
                className="app-link play-button"
                disabled={!username || (isMobile && !isLandscape)}
                onClick={() => setIsGameStarted(true)}
              >
                PLAY
              </button>
            </form>
          )}
        </header>
      )}
    </div>
  );
}
