import { Route, Routes as ReactRoutes } from 'react-router-dom';

import { GameContainer } from './components';

const ExternalRedirect = ({ to }) => {
  window.location.href = to;
  return null;
};

export const Routes = () => {
  return (
    <ReactRoutes>
      <Route
        path="/"
        element={<ExternalRedirect to="https://wellplay.pl/" />}
      />
      <Route
        path="/:mode"
        element={<GameContainer />}
      />
    </ReactRoutes>
  )
}
