export const MODES = {
  affirmations: 'affirmation-catcher',
  biznes: 'biznes',
  graNdera: 'gra-ndera',
  struny: '21s',
  xtm: 'xtm',
};

export const switchModes = (mode, biz, xtm, graNdera, struny, affirmations) => {
  switch (mode) {
    case MODES.biznes:
      return biz;
    case MODES.xtm:
      return xtm;
    case MODES.graNdera:
      return graNdera;
    case MODES.struny:
      return struny;
    case MODES.affirmations:
      return affirmations;
    default:
      return struny;
  }
}
