// All sprites loaded to the game are defined here
// SPRITES and AVATARS are loaded from '/public/sprites' directory
// SPRITES and AVATARS are fully customizable by client

// Firebase: databaseName is the name of the collection in Firebase

export const configXtm = {
  mode: 'xtm',
  backgrounds: {
    gameBg: '../../assets/bg-xtm.jpg',
  },
  databaseName: 'scoresXtm',
  spritesRoot: '/sprites/',
  sprites: {
    answer1: 'answer1.png',
    answer2: 'answer2.png',
    answer3: 'answer3.png',
    answer4: 'answer4.png',
    answer5: 'answer5.png',
    comment: 'comment.svg',
    commentLeft: 'commentLeft.svg',
    commentRight: 'commentRight.svg',
    player: 'duck.svg',
    playerMovingLeft: 'duck-moving-left.svg',
    playerMovingRight: 'duck-moving-right.svg',
  },
  avatars: [
    {
      id: 'avatar1',
      icon: 'ania.png',
      name: 'Ania',
      texts: [
        'Pierogi are the best!',
        'Always keep fighting!',
        '1000 years of history.',
        'Cześć!',
        'Bieszczady, my sanctuary.',
        'Kocham Warszawę!',
        'Solidarność forever.',
        'Soplica, anyone?',
        'PGE Narodowy, pride!',
        'Złoty rules!',
        'Vistula, our river.',
        'Chopin, our genius.',
        'Zapiekanka, street food!',
        'Home is home.',
        'Wawel Dragon, fire!',
        'Polish jazz, pure soul.',
        'Polish design, unique.',
        'Żurek, yum!',
        'Kurwa!',
        'Family first.',
        'Śmigus-Dyngus, water fun!',
        'Wigilia, family time.',
        'Paczki, sweet life.',
        'Adam Małysz, ski legend.',
        'Góral music, dance!',
      ],
    },
    {
      id: 'avatar2',
      icon: 'john.png',
      name: 'John',
      texts: [
        'Keep calm and carry on.',
        'A proper cuppa tea!',
        'Love a good pub.',
        'Mind the gap!',
        'Cheerio!',
        'Fish and chips, mate!',
        'God save the Queen!',
        'Brexit, what a ride.',
        'Union Jack, pride.',
        'Double-decker, hop on!',
        '007, Bond, James Bond.',
        'The Beatles, iconic.',
        'Tea time, 4 PM.',
        'Rain? Bring it on.',
        'Royal Family, intrigue.',
        'Football or soccer?',
        'Wimbledon, strawberries and cream.',
        'Cricket, not just a bug.',
        'Glastonbury, music heaven.',
        'Sherlock, elementary!',
        'Stonehenge, ancient mystery.',
        'Pimm`s o`clock!',
        'Doctor Who, time travel.',
        'Oxford vs Cambridge.',
        'Harry Potter, our export.',
        'Black cab, hop in!',
      ],
    },
    {
      id: 'avatar3',
      icon: 'emily.png',
      name: 'Emily',
      texts: [
        'Living the dream!',
        'Yoga vibes.',
        'Go big or go home.',
        'Route 66, baby!',
        'Howdy!',
        'Starbucks or Dunkin`?',
        'Fourth of July, fireworks!',
        'NYC, the Big Apple.',
        'Hollywood, movie magic.',
        'Silicon Valley, tech life.',
        'Hollywood, starstruck.',
        'Super Bowl, go team!',
        'Wall Street, bulls and bears.',
        'NBA slam dunk!',
        'Country music, yeehaw!',
        'Spring break, party!',
        'Thanksgiving, turkey time.',
        'Vegas, roll the dice.',
        'Disneyland, happiest place.',
        'In-N-Out, burger love.',
        'Soul food, comfort.',
        'Jeans and tees.',
        'Craft beer, cheers!',
        'Mardi Gras, beads on!',
        'Freedom, bald eagle.',
        'Freedom, bald eagle.',
      ],
    },
    {
      id: 'avatar4',
      icon: 'hiroshi.png',
      name: 'Hiroshi',
      texts: [
        'Bushido, the way.',
        'Tea ceremony, inner peace.',
        'Kon`nichiwa!',
        'Tech from the future.',
        'Sumo, power and skill.',
        'Samurai, ancient warriors.',
        'Sushi, art of food.',
        'Tokyo, city of lights.',
        'Anime, beyond cartoons.',
        'Zen, mind balance.',
        'Mount Fuji, majestic.',
        'Haiku, words in rhythm.',
        'Kyoto, old meets new.',
        'Karaoke, sing out loud!',
        'Nintendo, childhood memories.',
        'Manga, comics for all.',
        'Bullet train, speed!',
        'Origami, paper magic.',
        'Kawaii, cuteness overload.',
        'Geisha, grace and beauty.',
        'Ikebana, flower arrangement.',
        'J-Pop, dance to it!',
        'Manga, read backwards.',
        'Wasabi, spice it up!',
        'Yokai, mythical creatures.',
      ],
    },
  ],
};
