// All sprites loaded to the game are defined here
// SPRITES and AVATARS are loaded from '/public/sprites' directory
// SPRITES and AVATARS are fully customizable by client

// Firebase: databaseName is the name of the collection in Firebase

export const configNder = {
  mode: 'gra-ndera',
  backgrounds: {
    gameBg: '../../assets/bg-nder.jpg',
  },
  databaseName: 'scoresNder',
  spritesRoot: '/sprites/',
  sprites: {
    answer1: 'answer1.png',
    answer2: 'answer2.png',
    answer3: 'answer3.png',
    answer4: 'answer4.png',
    answer5: 'answer5.png',
    comment: 'present.svg',
    commentLeft: 'commentLeft.svg',
    commentRight: 'commentRight.svg',
    player: 'nder.svg',
    playerMovingLeft: 'nder-left.svg',
    playerMovingRight: 'nder-right.svg',
  },
  avatars: [
    {
      id: 'avatar1',
      icon: 'geodotka.png',
      name: 'Geodotka',
      texts: [
        'Spiewamy: Sto lat sto lat niech żyje żyje nam...',
        'Brakuje przestawienia jakiejś wajchy w foreście',
        'Drobnica do rozważenia i będziemy wciągać',
        'Muszę zjeść, bo mi będzie słabo',
        'Koń-sola coś krzyczy',
        'Stuk puk puk stuk',
        'Hłe hłe',
        'Można się zdzwonić?',
        'Dziń dybry, dziń dybry',
        'Oddaję na dostylowanie',
        'To będzie do skoŃtrolowania',
        'Trzeba to karfakować',
        'To jest po mojej strunie',
        '@here, brzuchy głodne?',
        'Idziemy na jedzonki?',
        'Ja jestem niejadkiem',
        'Koniec pokazu!',
      ],
    },
    {
      id: 'avatar2',
      icon: 'kawa.png',
      name: 'Kawa',
      texts: [
        'O teraz niezły numer!',
        'To jest do zrobienia jak najbardziej',
        'Mianowicie…',
        'Oksi, ja dziś w kaczkach siedzę',
        'Ok, dopisuję w takim razie',
        'Wszystkiego naj naj naj naj...',
        'Polecam zagrać w naszą grę',
        'Donoszę, że WCAG jest na produkcji',
        'Wyklarował mi sie urlop',
        'Czy ktoś ma do mnie jakąś pilną sprawkę?',
        'Potrzebuję dziś +5 min do calla',
        'Hop hop, wpadniesz dziś czy las Cię pochłonął?',
        'Halo halo, to wy zniknęliście czy ja?',
      ],
    },
    {
      id: 'avatar3',
      icon: 'frejr.png',
      name: 'Frejr',
      texts: [
        'Works for me',
        'ipv6 się skończyło na pot-1',
        'Wygląda na to, że ktoś coś spsuł w konfiguracji',
        'Jak pilne? Mam lazaret w domu.',
        'Bo to jest zEBane',
        'W skali 1..100 jak pilne?',
        'Gdzie to stoi? Z komórki walne.',
        'Nie mam jak za bardzo sprawdzić, bo jestem w drodze :(',
        'wysyscona ilość połączeń na nginxie',
        'ELM robi na 100%',
        'To co umiałem - naprawiłem. Ale wciąż najchętniej bym to zaorał',
        'Niestety nie mam kompa',
        'I to jest powód, czemu nie lubię bawić się w pocztę.',
        'Dobra… Najkrócej, jak się da. Spróbuję też najprościej.',
        '"Nie da" to się otworzyć parasola w doopie ;-)',
        'Sto lat sto lat niech żyje żyje nam...',
      ],
    },
    {
      id: 'avatar4',
      icon: 'arimr.png',
      name: 'Ania i Sylwek',
      texts: [
        'Spoko spoko',
        'Więc to jest jakby…',
        'Ta prezentacja jest kamieniem milowym',
        'To też jakby pokazuje…',
        'To już jest duża szansa',
        'To ma wartość',
        'No ma to jakiś walor, ale…',
        'Wszystkie inne opcje zlasują mi mózg',
        'Wszystkiego najlepszego!',
      ],
    },
  ],
};
