import { isMobile } from 'react-device-detect';

import { MODES } from '../helpers';

const COMMENT_SPEED = isMobile ? 20 : 40;
const GRAVITY_SPEED = isMobile ? 800 : 1200;
const PLAYER_SPEED = isMobile ? 450 : 500;
const COMMENTS_FREQUENCY = 4;
const COMMENTS_FREQUENCY_INCREASE = .1;

export class GameManager {

  constructor(k, gameConfig) {
    this.gameConfig = gameConfig;
    this.assetRoot = this.gameConfig.spritesRoot;
    this.k = k;
    this.width = this.k.width();
    this.height = this.k.height();
    this.keyDown = {
      left: false,
      right: false,
    };
    this.isBtnLeftOpaque = false;
    this.isBtnRightOpaque = false;
  };

  resetParameters() {
    this.keyDown = {
      left: false,
      right: false,
    };
    this.isBtnLeftOpaque = false;
    this.isBtnRightOpaque = false;
  }

  // Load game elements
  loadAssets() {
    this.k.loadRoot(this.assetRoot);
    // Load avatars sprites
    for (const avatar of this.gameConfig.avatars) {
      this.k.loadSprite(avatar.id, avatar.icon);
    };
    // Load answers, comments and player sprites
    for (const [key, value] of Object.entries(this.gameConfig.sprites)) {
      this.k.loadSprite(`${key}`, value);
    };

    // Load default consts sprites
    this.k.loadSprite('btnLeft', 'btn-left.svg');
    this.k.loadSprite('btnRight', 'btn-right.svg');
    this.k.loadSprite('finishBg', 'finish_bg.png');
    this.k.loadSprite('nameBg', 'name-bg.svg');
    this.k.loadSprite('scoreBg', 'score-bg.png');
    this.k.loadSprite('finishBtnBg', 'finish-btn-bg.svg');
  };

  addSceneElements = (isInGameScene) => {
    // Add floor
    this.k.add([
      this.k.rect(this.width, 10),
      this.k.pos(0, this.height - 10),
      this.k.area(),
      this.k.body({ isStatic: true }),
      this.k.color(0, 0, 0),
      this.k.opacity(0.2),
      'bottom',
    ]);

    // Add walls
    this.k.add([
      this.k.rect(20, this.height),
      this.k.pos(0, 0),
      this.k.outline(0),
      this.k.area(),
      this.k.body({ isStatic: true }),
      this.k.opacity(0),
    ]);

    this.k.add([
      this.k.rect(20, this.height),
      this.k.pos(this.width - 20, 0),
      this.k.outline(0),
      this.k.area(),
      this.k.body({ isStatic: true }),
      this.k.opacity(0),
    ]);

    if (isInGameScene) {
      // Add score label background
      this.k.add([
        this.k.sprite('scoreBg'),
        this.k.pos(this.width / 2, isMobile ? 20 : 30),
        this.k.scale(isMobile ? .6 : 1),
        this.k.anchor('center'),
      ]);
    };
  };

  addAvatar = (sprite, pos, tag, anchor = 'topleft') => {
    return this.k.add([
      this.k.sprite(sprite),
      this.k.pos(pos.x, pos.y),
      this.k.scale(isMobile ? .7 : 1),
      this.k.area(),
      this.k.anchor(anchor),
      'avatar',
      tag,
    ]);
  };

  addDesk = (width, pos, tag, anchor = 'topleft') => {
    return this.k.add([
      this.k.rect(width, 10),
      this.k.pos(pos.x, pos.y),
      this.k.area(),
      this.k.body({ isStatic: true }),
      this.k.color(246, 37, 103),
      this.k.anchor(anchor),
      'desk',
      tag,
    ]);
  };

  addNameBackground = (pos, tag, anchor = 'topleft') => {
    return this.k.add([
      this.k.sprite('nameBg'),
      this.k.pos(pos.x, pos.y),
      this.k.area(),
      this.k.scale((isMobile || this.gameConfig.mode === MODES.xtm) ? .6 : .75),
      this.k.anchor(anchor),
      tag,
    ]);
  };

  addName = (name, pos, tag, anchor = 'topleft') => {
    this.k.add([
      this.k.text(name,
        {
          size: (isMobile || this.gameConfig.mode === MODES.biznes) ? 14 : 16,
          width: 90,
        },
      ),
      this.k.pos(pos.x, pos.y),
      this.k.area(),
      this.k.color(0, 0, 0),
      this.k.anchor(anchor),
      tag,
    ]);
  };

  // invisible clickable area on the left and right side of the screen
  addBtn = (posX, anchor, width = 200) => {
    return this.k.add([
      this.k.rect(width, this.height),
      this.k.pos(posX, 0),
      this.k.area(),
      this.k.fixed(),
      this.k.color('#0022cc'),
      this.k.opacity(0),
      this.k.z(100),
      this.k.anchor(anchor),
    ]);
  };

  addUiForBtn = (sprite, pos, anchor = 'topleft') => {
    return this.k.add([
      this.k.sprite(sprite),
      this.k.pos(pos.x, pos.y),
      this.k.scale(isMobile ? .4 : .5),
      this.k.area(),
      this.k.fixed(),
      this.k.opacity(1),
      this.k.anchor(anchor),
    ]);
  };

  addScoreLabel = (score) => {
    return this.k.add([
      this.k.text(score, { size: isMobile ? 34 : 48 }),
      this.k.pos(this.width / 2, isMobile ? 20 : 30),
      this.k.anchor('center'),
      this.k.color(0, 0, 0),
      { value: 0 },
    ]);
  };

  addPlayer = () => {
    return this.k.add([
      this.k.sprite('player'),
      this.k.pos(this.width / 2, 50),
      this.k.body(),
      this.k.area(),
      this.k.anchor('center'),
      this.k.scale(isMobile ? .6 : .7),
    ]);
  };

  addComment = (pos, avatarsLeft, directory) => {
    return this.k.add([
      this.k.sprite('comment'),
      this.k.pos(pos),
      this.k.body(),
      this.k.area(),
      this.k.scale(isMobile ? .3 : .5),
      this.k.anchor(avatarsLeft ? 'botleft' : 'botright'),
      { directory },
      'comment',
    ]);
  };

  // Add instruction texts
  addInstructionText = (text, big = false) => {
    return this.k.add([
      this.k.text(text, {
        align: 'center',
        lineSpacing: isMobile ? 8 : 10,
        size: big ? 25 : isMobile ? 11 : 15,
        width: this.width / (isMobile ? 4 : 3),
      }),
      this.k.pos(this.width / 2, isMobile ? 40 : 60),
      this.k.anchor('center'),
      this.k.area(),
      this.k.color(0, 0, 0),
    ]);
  };

  // Define actions
  spawnComment = (isInGame = false, scoreLabel) => {
    const avatars = this.k.get('avatar');
    if (avatars.length === 0) return;

    const avatar = this.k.choose(avatars);
    const avatarsLeft = this.k.get('avatarLeft').includes(avatar);
    const { pos: { x: avatarPosX, y: avatarPosY } } = avatar;

    const pos = [
      (avatarsLeft)
        ? avatarPosX + (isMobile ? 60 : 90)
        : avatarPosX - 20,
      avatarPosY + (isMobile ? 60 : 90),
    ];
    const directory = (avatarsLeft) ? 1 : -1;

    const comment = this.addComment(pos, avatarsLeft, directory);
    // destroy comment when it collides with floor
    comment.onCollide('bottom', (b) => {
      if (isInGame) {
        this.k.destroy(b);

        // Go to lose scene after 0.5 seconds
        this.k.wait(.5, () => {
          this.goToScene('lose', {
            timeGapBetweenComments: COMMENTS_FREQUENCY,
            commentSpeed: COMMENT_SPEED,
            score: scoreLabel.value,
          });
        });
      };
      this.k.destroy(comment);
    })

    if (!isInGame) return;

    // Add comment buble next to avatar
    const avatarBublePos = [
      (avatarsLeft)
        ? avatarPosX + 22
        : avatarPosX - 22,
      avatarPosY + 15,
    ];

    const avatarTextBuble = this.k.add([
      this.k.sprite(avatarsLeft ? 'commentLeft' : 'commentRight'),
      this.k.pos(avatarBublePos),
      this.k.area(),
      this.k.scale(isMobile ? .5 : .7),
      this.k.anchor(avatarsLeft ? 'botleft' : 'botright'),
    ]);

    const index = avatars.indexOf(avatar);
    const text = this.k.choose(this.gameConfig.avatars[index].texts);

    const displayedText = this.k.add([
      this.k.text(text, {
        lineSpacing: isMobile ? 3 : 4,
        size: isMobile ? 11 : 14,
        width: this.width / 4,
        height: isMobile ? 25 : 32,
      }),
      this.k.pos([
        avatarTextBuble.pos.x + (isMobile ? (avatarsLeft ? 15 : 2) : (avatarsLeft ? 20 : -20)),
        avatarTextBuble.pos.y - (isMobile ? 23 : 36),
      ]),
      this.k.anchor(avatarsLeft ? 'botleft' : 'botright'),
      this.k.color(0, 0, 0),
    ]);

    this.k.wait(3, () => this.k.destroy(avatarTextBuble));
    this.k.wait(2.95, () => this.k.destroy(displayedText));
  };

  move = (player, sprite, playerSpeed) => {
    player.use(this.k.sprite(sprite));
    player.move(playerSpeed, 0);
  };

  moveComments = (commentSpeed) => {
    // Increase speed of comments every 20 seconds
    this.k.loop(20, () => commentSpeed += 10);
    // Move comments
    this.k.onUpdate('comment', (c) => c.move(c.directory * commentSpeed, 0));
  };

  // Player collides with comments
  collidePlayerWithComments = (player, scoreLabel, setGameResult) => {
    if (!player) return;

    player.onCollide('comment', (c) => {
      this.k.destroy(c);
      scoreLabel.value++;
      scoreLabel.text = scoreLabel.value.toString();
      setGameResult({ score: scoreLabel.value });

      const randomAnswer = Math.floor(this.k.rand(1, 5));
      const answer = this.k.add([
        this.k.sprite(`answer${randomAnswer}`),
        this.k.pos(player.pos.x, player.pos.y - (isMobile ? 120 : 150)),
        this.k.area(),
        this.k.scale(isMobile ? .6 : .8),
      ]);
      this.k.shake(2);
      this.k.wait(1, () => this.k.destroy(answer));
    });
  };

  managePlayerMove = (player, isInGame) => {
    // Add buttons
    const leftBtn = this.addBtn(0, 'topleft');
    const rightBtn = this.addBtn(this.width, 'topright');

    const leftUiBtn = this.addUiForBtn('btnLeft', { x: 15, y: this.height - 15 }, 'botleft');
    const rightUiBtn = this.addUiForBtn('btnRight', { x: this.width - 15, y: this.height - 15 }, 'botright');

    const moveLeft = () => {
      this.move(player, 'playerMovingLeft', -PLAYER_SPEED);

      // change opacity of the buttons after 5 seconds after click
      if (isInGame && !this.isBtnLeftOpaque) {
        this.isBtnLeftOpaque = true;
        const wait = this.k.wait(5, () => {
          leftUiBtn.opacity = .4;
          if (this.isBtnLeftOpaque) wait.cancel();
        });
      }
    };

    const moveRight = () => {
      this.move(player, 'playerMovingRight', PLAYER_SPEED);
      // change opacity of the buttons after 5 seconds after click
      if (isInGame && !this.isBtnRightOpaque) {
        this.isBtnRightOpaque = true;
        const wait = this.k.wait(5, () => {
          rightUiBtn.opacity = .4;
          if (this.isBtnRightOpaque) wait.cancel();
        });
      }
    };

    // keyboard
    this.k.onKeyDown('left', () => this.keyDown.left = true);
    this.k.onKeyRelease('left', () => this.keyDown.left = false);
    this.k.onKeyDown('right', () => this.keyDown.right = true);
    this.k.onKeyRelease('right', () => this.keyDown.right = false);

    // mouse
    this.k.onMouseDown('left', () => {
      if (isMobile) return;
      if (this.k.mousePos().x < 100) {
        moveLeft();
      }
      if (this.k.mousePos().x > this.width - 100) {
        moveRight();
      }
    });

    // touch
    this.k.onTouchStart((pos) => {
      if (!isMobile) return;
      if (leftBtn.hasPoint(pos)) {
        this.keyDown.left = true;
      } else if (rightBtn.hasPoint(pos)) {
        this.keyDown.right = true;
      }
    });

    this.k.onTouchEnd(() => {
      this.keyDown.left = false;
      this.keyDown.right = false;
    });

    this.k.onUpdate(() => {
      if (this.keyDown.left) {
        moveLeft();
      } else if (this.keyDown.right) {
        moveRight();
      }
    });
  }

  addCommonSceneElements = (isInGame) => {
    this.k.setGravity(GRAVITY_SPEED);

    this.loadAssets();
    this.addSceneElements(isInGame);

    // Add avatars
    this.addAvatar('avatar1', { x: 5, y: this.height * .15 }, 'avatarLeft');
    this.addAvatar('avatar2', { x: this.width - 5, y: this.height * .15 }, 'avatarRight', 'topright');
    this.addAvatar('avatar3', { x: 5, y: this.height / 2 }, 'avatarLeft');
    this.addAvatar('avatar4', { x: this.width - 5, y: this.height / 2 }, 'avatarRight', 'topright');

    // Add desks
    this.addDesk(
      this.width / 2.6,
      { x: 0, y: this.height * .15 + (isMobile ? 80 : 115) },
      'deskUpLeft',
    );
    this.addDesk(
      this.width / 2.6,
      { x: this.width, y: this.height * .15 + (isMobile ? 80 : 115) },
      'deskUpRight',
      'topright',
    );
    this.addDesk(
      this.width / 4,
      { x: 0, y: this.height / 2 + (isMobile ? 80 : 115) },
      'deskDownLeft',
    );
    this.addDesk(
      this.width / 4,
      { x: this.width, y: this.height / 2 + (isMobile ? 80 : 115) },
      'deskDownRight',
      'topright',
    );
  };

  // Define scenes
  // Define tutorial scene: "tutorial"
  addTutorialScene = () => {
    this.k.scene('tutorial', () => {
      this.addCommonSceneElements();

      // Add instructions
      this.k.add([
        this.k.sprite('finishBtnBg'),
        this.k.pos(this.width / 2, isMobile ? 45 : 70),
        this.k.scale(isMobile ? 1.3 : 2),
        this.k.anchor('center'),
      ]);

      // Add player
      const player = this.addPlayer();

      this.managePlayerMove(player);

      // Add button indicators for moving instructions
      const leftBtbIndicator = this.addBtn(0, 'topleft', 130);
      const rightBtbIndicator = this.addBtn(this.width, 'topright', 130);

      // Display instructions
      let instruction = this.addInstructionText('Poruszaj kaczką w prawo lub w lewo naciskając boczne krawędzie ekranu lub strzałki w prawo/lewo.');

      // show button indicators
      let count = 0;
      let opacity = 0;

      this.k.loop(0.5, () => {
        if (count >= 8) {
          this.k.destroy(leftBtbIndicator);
          this.k.destroy(rightBtbIndicator);
          return;
        }

        opacity = opacity === 0.4 ? 0 : 0.4;
        leftBtbIndicator.opacity = opacity;
        rightBtbIndicator.opacity = opacity;
        count++;
      });

      this.k.wait(5, () => {
        this.k.destroy(instruction);
        instruction = this.addInstructionText('Zdobywaj punkty zbierając komentarze, które spadają z półek.');
      });

      this.k.wait(6, () => {
        let count = 0;

        const loop = this.k.loop(COMMENTS_FREQUENCY, () => {
          if (count >= 3) {
            loop.cancel();

            this.k.wait(0.5, () => {
              let timer = 3;
              const loop2 = this.k.loop(1, () => {
                if (timer <= 0) {
                  loop2.cancel();
                  this.goToScene('game', {
                    timeGapBetweenComments: COMMENTS_FREQUENCY,
                    commentSpeed: COMMENT_SPEED,
                    score: 0,
                  });
                  return;
                };

                this.k.destroy(instruction);
                instruction = this.addInstructionText(`Start za ${timer.toString()}...`, true);
                timer--;
              });
            });
            return;
          };
          this.spawnComment();
          count++;
        });

        this.moveComments(COMMENT_SPEED * 1.5);
      });

      player.onCollide('comment', (c) => {
        this.k.destroy(c);
        this.k.shake(2);
      });
    });
  };

  // Define main scene: "game"
  addMainScene = (setGameResult) => {
    this.k.scene('game', ({
      commentSpeed,
      score,
    }) => {
      this.addCommonSceneElements(true);

      // Add name bgs
      this.addNameBackground({ x: 0, y: this.height * .15 + (isMobile ? 80 : 115) }, 'nameBgUpLeft');
      this.addNameBackground({ x: this.width, y: this.height * .15 + (isMobile ? 80 : 115) }, 'nameBgUpRight', 'topright');
      this.addNameBackground({ x: 0, y: this.height / 2 + (isMobile ? 80 : 115) }, 'nameBgDownLeft');
      this.addNameBackground({ x: this.width, y: this.height / 2 + (isMobile ? 80 : 115) }, 'nameBgDownRight', 'topright');

      // Add avatar names
      this.addName(
        this.gameConfig.avatars[0].name,
        { x: 20, y: this.height * .15 + (isMobile ? 90 : 130) },
        'nameUpLeft',
      );
      this.addName(
        this.gameConfig.avatars[1].name,
        { x: this.width - 20, y: this.height * .15 + (isMobile ? 90 : 130) },
        'nameUpRight',
        'topright'
      );
      this.addName(
        this.gameConfig.avatars[2].name,
        { x: 20, y: this.height / 2 + (isMobile ? 90 : 130) },
        'nameDownLeft',
      );
      this.addName(
        this.gameConfig.avatars[3].name,
        { x: this.width - 20, y: this.height / 2 + (isMobile ? 90 : 130) },
        'nameDownRight',
        'topright',
      );

      // Add score label
      const scoreLabel = this.addScoreLabel(score);

      // Add player
      const player = this.addPlayer();
      this.managePlayerMove(player, true);

      player.onCollide('bottom', () => {
        // Spawn comments
        let timeGapBetweenComments = COMMENTS_FREQUENCY;
        let _wait;
        const _this = this;

        function manageSpawningComent() {
          _wait = _this.k.wait(timeGapBetweenComments, () => {
            if (timeGapBetweenComments > 1) {
              timeGapBetweenComments -= COMMENTS_FREQUENCY_INCREASE;
            }
            _this.spawnComment(true, scoreLabel);
            _wait?.cancel();
            manageSpawningComent();
          });
        }

        this.spawnComment(true, scoreLabel);
        manageSpawningComent();
      });

      this.moveComments(commentSpeed);
      this.collidePlayerWithComments(player, scoreLabel, setGameResult);
    });
  };

  // Define lose scene: "lose"
  addLoseScene = ( addScore, setIsGameFinished, setGameResult, username ) => {
    this.k.scene('lose', ({ score }) => {

      // Add scene elements
      this.k.add([
        this.k.sprite('finishBg'),
        this.k.fixed(),
        this.k.pos(this.k.center()),
        this.k.anchor('center'),
        { z: -1 },
      ]);

      this.k.add([
        this.k.text('GAME OVER', {
          size: isMobile ? 36 : 50,
        }),
        this.k.pos(this.width / 2, this.height / 2 - 60),
        this.k.anchor('center'),
        this.k.color(0, 0, 0),
      ]);

      this.k.add([
        this.k.text(`Score: ${score}`, {
          size: isMobile ? 28 : 40,
        }),
        this.k.pos(this.width / 2, this.height / 2),
        this.k.anchor('center'),
        this.k.color(0, 0, 0),
      ]);

      const buttonFinish = this.k.add([
        this.k.sprite('finishBtnBg'),
        this.k.pos(this.width / 3, this.height / 1.5),
        this.k.anchor('center'),
        this.k.scale(isMobile ? .8 : 1),
        this.k.area(),
      ]);

      const buttonPlayAgain = this.k.add([
        this.k.sprite('finishBtnBg'),
        this.k.pos(this.width - this.width / 3, this.height / 1.5),
        this.k.anchor('center'),
        this.k.scale(isMobile ? .8 : 1),
        this.k.area(),
      ]);

      this.k.add([
        this.k.text('Save score', {
          size: isMobile ? 20 : 26,
        }),
        this.k.pos(this.width / 3, this.height / 1.53),
        this.k.anchor('center'),
        this.k.color(0, 0, 0),
      ]);

      this.k.add([
        this.k.text('Play again', {
          size: isMobile ? 20 : 26,
        }),
        this.k.pos(this.width - this.width / 3, this.height / 1.53),
        this.k.anchor('center'),
        this.k.color(0, 0, 0),
      ]);

      // Define events
      buttonFinish.onClick(() => {
        const userId = Date.now();
        setGameResult({ userId, score })
        addScore(userId, score, username, () => setIsGameFinished(true));
      });

      buttonPlayAgain.onClick(() => {
        this.goToScene('game', {
          timeGapBetweenComments: COMMENTS_FREQUENCY,
          commentSpeed: COMMENT_SPEED,
          score: 0,
        });
      });
    });
  };

  // Go to scene
  goToScene = (sceneName, params) => {
    this.resetParameters();
    this.k.go(sceneName, params);
  };

  // Start game
  startGame = (
    addScore,
    setIsGameFinished,
    setGameResult,
    username,
  ) => {
    // Init scenes
    this.addTutorialScene();
    this.addMainScene(setGameResult);
    this.addLoseScene(addScore, setIsGameFinished, setGameResult, username);

    // Start the game with the "turorial" scene
    this.goToScene('tutorial');
  }
};
