// All sprites loaded to the game are defined here
// SPRITES and AVATARS are loaded from '/public/sprites' directory
// SPRITES and AVATARS are fully customizable by client

// Firebase: databaseName is the name of the collection in Firebase

export const config21s = {
  mode: '21s',
  backgrounds: {
    gameBg: '../../assets/bg-21s.png',
  },
  databaseName: 'scores',
  spritesRoot: '/sprites/',
  sprites: {
    answer1: 'answer1.png',
    answer2: 'answer2.png',
    answer3: 'answer3.png',
    answer4: 'answer4.png',
    answer5: 'answer5.png',
    comment: 'comment.svg',
    commentLeft: 'commentLeft.svg',
    commentRight: 'commentRight.svg',
    player: 'duck.svg',
    playerMovingLeft: 'duck-moving-left.svg',
    playerMovingRight: 'duck-moving-right.svg',
  },
  avatars: [
    {
      id: 'avatar1',
      icon: 'accountant.png',
      name: 'Mariola 21s',
      texts: [
        'HR to lekarz dla organizacji.',
        'HR to obsługa marzeń.',
        'HR to nie tylko rekrutacja.',
      ],
    },
    {
      id: 'avatar2',
      icon: 'designer.png',
      name: 'UX-spec 21s',
      texts: [
        'Piękne czy niepiękne?',
        'Myślę o UX, UI, AI, USB, USA...',
      ],
    },
    {
      id: 'avatar3',
      icon: 'developer.png',
      name: 'Roman 21s',
      texts: [
        'Dziwne, u mnie działa!',
        'To nie bug, to ficzer!',
        'To zależy...',
      ],
    },
    {
      id: 'avatar4',
      icon: 'boss.png',
      name: 'Boss 21s',
      texts: [
        'Zrobić, żeby było dobrze.',
        'Działamy!',
      ],
    },
  ],
};
